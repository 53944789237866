import React, { Component } from 'react';
import {  Switch } from 'react-router-dom';
import {
    HashRouter as Router,
    Route,
} from 'react-router-dom';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';


import Home from "./Home";
import List from "./List";
import Error from "./Error";
import Noprofile from "./Noprofile";
import Jobsingle from "./Jobsingle";
import Quiz from "./Quiz";
import Listquiz from "./Listquiz";




class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <Switch>
                        <Route path="/" component={Home} exact />
                        <Route path="/list" component={List} />
                        <Route path={"/jobsingle/:id"} component={Jobsingle} />
                        <Route path={"/quiz"} component={Quiz} />
                        <Route path={"/listquiz/:u"} component={Listquiz} />
						<Route path={"/noprofile"}component={Noprofile} />
                        <Route component={Error} />
						
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;
