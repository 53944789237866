import React, { Component } from 'react';
import $ from "jquery";



import Header from './Header';
import Footer from './Footer';
import HelperClass from "./HelperClass";

class List extends Component {

    constructor(props) {
        super(props);
        this.helper = new HelperClass();
        this.state = {
            lists: [],
            sectors: [],
            filter: localStorage.getItem("filter") === null ? [] : JSON.parse(localStorage.getItem('filter')),
            selectall: localStorage.getItem("selectall") === null ? false : JSON.parse(localStorage.getItem('selectall')),
			      joblist: [],
            variables: [],
            output: [],
            base_path: this.helper.basepath(),
        };
        this.handleChecked = this.handleChecked.bind(this);
    }

    componentDidMount() {
      const { base_path } = this.state;
      var LISTAPI, SECTORS, VariablesAPI, DOORPAGE = '';
      if(typeof this.helper.getOnlineStatus() !== 'undefined') {
        if (this.helper.getOnlineStatus()) {
          LISTAPI = base_path + '/drupal/job-detail';
          SECTORS = base_path + '/drupal/sectors';
          VariablesAPI = base_path + '/drupal/initvariables'
          DOORPAGE = base_path + '/drupal/doorpage';
        }
        else {
          LISTAPI = base_path + '/json/job-detail.json';
          SECTORS = base_path + '/json/sectors.json';
          VariablesAPI = base_path + '/json/initvariables.json'
          DOORPAGE = base_path + '/json/doorpage.json';
        }

        fetch(LISTAPI)
            .then(response => response.json())
            .then(data => this.setState({lists: data}));
        fetch(SECTORS)
            .then(response => response.json())
            .then(data => this.setState({sectors: data}));
        fetch(DOORPAGE)
            .then(response => response.json())
            .then(data => this.setState({joblist: data.filter(p => p.field_machine_name === 'joblist')}));
        fetch(VariablesAPI)
            .then(response => response.json())
            .then(data => this.setState({variables: data}));
      }
	    this.unSelectAllCheck();

    }



  componentDidUpdate(prevProps, prevState) {
    // Adjusting functions
    this.helper.playAudio();
    window.addEventListener('load', this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.pageHeight.bind(this));
    this.helper.gotoTop();
    window.addEventListener("resize", this.helper.gotoTop.bind(this));
    this.helper.jobFilter();
    window.setTimeout(this.helper.pageHeight, 1000);
    window.setTimeout(this.helper.gotoTop, 1000);
    this.helper.shareIcons();

  }

  handleChecked (event) {
    var temp = this.state.filter;
    if(event.target.checked) {
      temp.push(event.target.value);
      this.setState({filter: temp});
      localStorage.setItem('filter', JSON.stringify(temp));
    }else{
      var temp1 = this.removeFilterItem(temp, event.target.value);
      this.setState({filter: temp1});
      localStorage.setItem('filter', JSON.stringify(temp1));
    }
    // store into local storage.
  }

  removeFilterItem (filter ,removeItem) {
    filter = filter.filter(function(item) {
      return item !== removeItem
    });
    return filter;
  }


  renderJobGrid(output, base_path) {
    return (
        <section className="listed-jobs full-width">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="list-counts mt-35 mb-10">{output.filter(function(value) { return value !== undefined }).length} مهنة</div>
            </div>
          </div>
          <div className="row all-jobs job-glossary-items">
            {output.map(item =>
                <div  key={item.nid} className="col-12 col-sm-6 col-lg-4 col-print-4 job-item-blocks">
                  <a href={'#/jobsingle/' + item.nid}>
                    <article className="job-items">
                      <figure>
                        <img src={ base_path + item.field_job_image } alt="Job tittle" className="img-horzontal"/>
                      </figure>
                      <div className="full-width ar-title pl-15 pr-15">
                        <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                      </div>
                      <div className="full-width fr-title pl-15 pr-15">
                           <h2 dangerouslySetInnerHTML={{ __html: item.field_french_title }}></h2>
                      </div>


                    </article>
                  </a>
                </div>
            )}
          </div>
        </section>
    );
  }

  selectAllCheck() {
    this.setState({selectall: !this.state.selectall});
    this.setState({filter: []});
    localStorage.setItem('selectall', !this.state.selectall);
    localStorage.setItem('filter', JSON.stringify([]));
    // Disable the checkbox
    $('input:checkbox').attr('disabled', !this.state.selectall);
  }
  
  unSelectAllCheck() {
    this.setState({selectall: false});
    this.setState({filter: []});
    localStorage.setItem('selectall', false);
    localStorage.setItem('filter', JSON.stringify([]));
  }

    render() {
        const width = $(document).width();
        const { filter } = this.state;
        const { selectall } = this.state;
        const { lists } = this.state;
        const { sectors } = this.state;
        const { joblist } = this.state;
	    const { variables } = this.state;
        const { base_path } = this.state;
		localStorage.setItem('previouPage', window.location.hash);
        // list according to filter
        if (filter.length !== 0) {
          var updateLists = [];
          filter.map(f =>
              updateLists.push(lists.filter(l => l.field_sector === f ))
          )
        }
        var output = [];
        if(updateLists) {
          // converting 2D to 1D
          var newArr = [];
          for(var i = 0; i < updateLists.length; i++)
          {
            newArr = newArr.concat(updateLists[i]);
          }
          output = newArr;
        }
        // if(selectall === true) {
        //   output = lists;
        // }
      if(filter.length === 0) {
        output = lists;
      }
        // Eliminate Duplicate
        var uniqueoutput = [];
        for(i = 0; i < output.length; i++) {
            uniqueoutput[output[i].nid] = output[i];
        }
        // Removing the empty index from array.
        output = uniqueoutput.filter(function (el) {
          return el != null;
        });
        //sorting
        var varsort = [];
        if(output !== undefined && output.length !== 0) {
          for (i = 0; i < output.length; i++) {
              varsort[output[i].field_weight] = output[i];
          }
        }
        return (
            <div className="Home">
               {joblist.map(page => <Header key={page.field_machine_name} audioFile={page.field_job_audio_file} basepath={base_path} />) }
			   
                <div className="content-block full-width">
                <div className="container">
                  <section className="job-list full-width">
                    <div className="row">
                      {joblist.map(page => 
					   <div key={page.field_machine_name}  className="col-12 page-descriptions mb-30">
                           <h1 dangerouslySetInnerHTML={{ __html: page.title }}></h1>
					        <div dangerouslySetInnerHTML={{ __html: page.body }}></div>
						</div>
						)}
                    </div>

                    <div className="row">
                      <div className="col-12 padding-gutters">
                        <div className="full-width job-filter">
                          <button type="button" className="btn job-filter-btn">
                            <span className="job-filter-icon"></span>
                            إختيار القطاعات
                          </button>
                        </div>
                        <div className="full-width job-filter-items">

                          {/*<p>*/}
                            {/*<a href="javascript:void(0)" onClick={this.selectAllCheck.bind(this)} className="select-all">*/}
                              {/*{ selectall === false &&*/}
                              {/*<span*/}
                                  {/*className="ar-select">عرض جميع القطاعات</span>*/}
                              {/*}*/}
                              {/*{ selectall === true &&*/}
                              {/*<span*/}
                                  {/*className="ar-select">إلغاء جميع الإختيارات</span>*/}
                              {/*}*/}
                            {/*</a>*/}
                          {/*</p>*/}
                          { selectall === false &&
                          <ul>
                            {
                              sectors.map(sector =>
                                  <li key={sector.tid}><input value={sector.tid} onChange={ (event) => this.handleChecked(event) } type="checkbox" className="checkbox-class" id={sector.tid} checked={this.state.filter.indexOf(sector.tid) > -1 ? 'checked' : ''} /><label htmlFor={sector.tid}><span className="label-tag"><span className="ar" ><span dangerouslySetInnerHTML={{ __html:sector.name}}></span><span className="filter-icons"><img
                                      src={base_path + sector.field_sector}
                                      alt="icons"/></span></span><span className="en" dangerouslySetInnerHTML={{ __html: sector.field_french_name }}></span></span>
                                  </label>
                                  </li>
                              )
                            }
                          </ul>
                          }
                          { selectall === true &&
                          <ul>
                            {
                              sectors.map(sector =>
                                  <li key={sector.tid}><input value={sector.tid} onChange={ (event) => this.handleChecked(event) } type="checkbox" checked={true} className="checkbox-class" id={sector.tid}/><label htmlFor={sector.tid}><span className="label-tag"><span className="ar"><span dangerouslySetInnerHTML={{ __html:sector.name }}></span><span className="filter-icons"><img
                                      src={base_path + sector.field_sector}
                                      alt="icons"/></span></span><span className="en" dangerouslySetInnerHTML={{ __html: sector.field_french_name }}></span></span>
                                  </label>
                                  </li>
                              )
                            }
                          </ul>
                          }


                        </div>

                      </div>
                    </div>

                  </section>

                  {output.length !== 0 && this.renderJobGrid(varsort, base_path)}

                  {/*output.length === 0 &&
                  <div className="row no-gutters no-object">
                    <div className="col-12">
                      <p>يرجى تحديد مرشح مقدما.</p>
                    </div>
                  </div>*/}

                  <section className="available-list full-width pt-10">
                    <div className="row">
                      <div className="col-12">
                        { width > 1024 &&
                        <div>
                          <p className="mb-25" dangerouslySetInnerHTML={{ __html: variables.download_description }}></p>
                          {/*<a href="mailto:#" className="btn btn-start-quiz mb-20">إرسال إلى بريد إلكتروني   <span className="send-mail"></span></a>*/}
                          <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                        </div>
                        }
                        { width < 1024 &&
                        <div>
                          <p className="mb-25" dangerouslySetInnerHTML={{ __html: variables.share_description }}></p>
                          <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                          <span className="share-icons">
									   <button href="#" className="btn btn-start-quiz mb-20 share-btn">إرسال
										   <span className="share"></span>
										</button>
										<span className="social-icons clearfix">
											<ul>
												<li><a rel="noopener noreferrer" target="_blank" href={ "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash)  }
                               className="fb"></a></li>
											  <li><a rel="noopener noreferrer" target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }
                               className="wt"></a></li>
                        {/*<li><a target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }*/}
                        {/*className="em"></a></li>*/}
											</ul>
										</span>
									</span>
                        </div>
                        }
                      </div>
                    </div>
                  </section>
                </div>
              </div>
                <Footer />
            </div>
        );
    }
}

export default List;
