import React, { Component } from 'react';
import {  Redirect } from 'react-router'

import Header from './Header';
import Footer from './Footer';
import HelperClass from './HelperClass.jsx'

class Quiz extends Component {
    constructor(props) {
        super(props);

        this.helper = new HelperClass();
        this.state = {
            competence: [],
            taxo: [],
            count:0,
            maxCount:0,
            base_path: this.helper.basepath(),
        };
    }
    componentDidMount() {
        const { base_path } = this.state;
        var LISTAPI = '';
        if(this.helper.getOnlineStatus()) {
          LISTAPI = base_path + '/drupal/competence';
        }else{
          LISTAPI = base_path + '/json/competence.json';
        }
        fetch(LISTAPI)
            .then(response => response.json())
            .then(data => this.setState({ competence: data , maxCount: data.length }));
    }

  componentDidUpdate(prevProps, prevState) {
    // Adjusting functions
    this.helper.playAudio();
    window.addEventListener("resize", this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.gotoTop.bind(this));
    this.helper.goOnTop();
    window.addEventListener('load', this.helper.pageHeight.bind(this));
    window.setTimeout(this.helper.pageHeight, 1000);
    window.setTimeout(this.helper.gotoTop, 1000);
  }

    renderImage(url, basepath) {
      return (
        <figure>
          <img src={basepath + url}
           alt="" className="img-vertical"/>
        </figure>
      );
    }

    renderCompetence ()  {

        const { competence  } = this.state;
        const { count  } = this.state;
        const { base_path } = this.state;
        const { maxCount }  = this.state;
        var count1 = count + 1;
        let per = (count1 / maxCount) * 100;

        let compet = competence[count];
        const style = {
          width: per + '%'
        };
        return (
            <div>
              <div className="row">
                <div className="col-12 questionnaire-title">
                  <h1 dangerouslySetInnerHTML={{ __html:compet.field_questionnaire_headline }}></h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="questionnaire-block clearfix">
                    <div className="img-block clearfix">
                      {this.renderImage(compet.field_image, base_path)}
                      {count > 0 && <a href="javascript:void(0)" onClick={()=>{this.tidPrevious(compet.tid)}} className="back-arrow"></a> }
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: compet.name }}></p>
                  </div>
                  <div className="btn-group-questionnaire mt-10 mb-35">
                    <button className="btn btn-boolean btn-true" onClick={()=>{this.saveTidNext(compet.tid)}}>True</button>
                    <span className="hidden-qs true"></span>
                    <button className="btn btn-boolean btn-false" onClick={()=>{this.tidNext(compet.tid)}} >False</button>
                    <span className="hidden-qs false"></span>
                  </div>
                  <div className="progress clearfix">
                    <div className="progress-bar mb-5">
                      <span style={style}></span>
                    </div>
                    <div className="progress-value">
                      <span className="answered">{count + 1} من </span><span
                        className="total">{maxCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );

    }
    saveTidNext(tid) {

        const { taxo } = this.state;
        taxo.push(tid);
        this.setState({taxo:taxo})
        this.setState({ count: this.state.count + 1 });
		this.helper.autoPause();

        }
    tidNext (tid) {
        this.setState({ count: this.state.count + 1 });
		this.helper.autoPause();


    }
    tidPrevious(tid) {
        this.setState({ count: this.state.count - 1 });

    }

    getAudioFile () {
      const { competence  } = this.state;
      const { count  } = this.state;
      if(competence) {
        let compet = competence[count];
        if (compet) {
          return compet.field_audio;
        }
      }
      return '';
    }

    render() {
        const { base_path } = this.state;
        const { competence  } = this.state;
        if(this.state.count === this.state.maxCount && this.state.maxCount > 0 ) {
            localStorage.setItem('taxoQuiz', this.state.taxo);
            // Make unique URL.
            var param = this.state.taxo;
			if(param.length === 0){
				return <Redirect to={{
				pathname: '/Noprofile'
				}} />;
			} else {
				return <Redirect to={{
					pathname: '/listquiz/' + param,
					state: { taxoQuiz: this.state.taxo}
				}} />;
			}
        }


        return (<div className="Home">
          <Header audioFile={this.getAudioFile()} basepath={ base_path } />
          <div className="content-block full-width">
            <div className="container">
              <div className="questions-page-content">
                {competence.length > 0 && this.renderCompetence()}
            </div>
            </div>
          </div>
            <Footer />
        </div>);
    }
}

export default Quiz;
