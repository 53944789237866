import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';
import HelperClass from "./HelperClass";

import servicelady from '../assets/images/illustration/service_lady.png';

class Home extends Component {
  constructor(props) {
    super(props);
    this.helper = new HelperClass();
    this.state = {
      Singles: [],
      CountNum: 0,
      home: [],
      base_path: this.helper.basepath(),
    };
  }
  componentDidMount() {
    const { base_path } =  this.state;
      var DOORPAGE = '';
      if(this.helper.getOnlineStatus()) {
        DOORPAGE = base_path + '/drupal/doorpage';
      }else{
        DOORPAGE = base_path + '/json/doorpage.json';
      }
      fetch(DOORPAGE)
          .then(response => response.json())
          .then(data => this.setState({ home: data.filter(p => p.field_machine_name === 'noprofile' ) }));
  }

  componentDidUpdate(prevProps, prevState) {
    this.helper.playAudio();
    window.addEventListener('load', this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.gotoTop.bind(this));
    this.helper.goOnTop();
    window.setTimeout(this.helper.gotoTop, 1000);
    window.setTimeout(this.helper.pageHeight, 1000);
  }
    render() {
      // Change the base path according to the server.
      const { base_path } = this.state;
      const { home } = this.state;
      if (typeof home !== "undefined" && home !== '') {
          return (
              <div className="Home">
                {home.map(h => <Header key={h.field_machine_name} audioFile={h.field_job_audio_file} basepath={base_path} />) }

                {home.map(h => 
				<div key={h.field_machine_name} className="content-block full-width">
                  <div className="container">
                    <div className="home-page-content clearfix">
						  <figure className="home-image">
							<img src={base_path + h.field_image} alt="No profile found"/>
						  </figure>

						 <div className="home-text" dangerouslySetInnerHTML={{ __html: h.body}}></div>

						  <div className="btn-group">
							  <a href="#/" className="btn btn-start-quiz">الرجوع لصفحة البداية</a>
						  </div>
                    </div>
                  </div>
                </div>)}

	
                <Footer />
              </div>
          );
      }
      
    }
}

export default Home;
