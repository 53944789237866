import React, { Component } from 'react';
import HelperClass from './HelperClass.jsx'

class Footer extends Component {
	 constructor(props) {
        super(props);
        this.helper = new HelperClass();
        this.state = {
            variables: [],
					  base_path: this.helper.basepath(),
        };
    }

    componentDidMount() {
	 	  const { base_path } = this.state;
	 	  var VariablesAPI = '';
      if(this.helper.getOnlineStatus()) {
        VariablesAPI = base_path + '/drupal/initvariables';
      }else{
        VariablesAPI = base_path + '/json/initvariables.json';
      }
      fetch(VariablesAPI)
          .then(response => response.json())
          .then(data => this.setState({ variables: data }));
		}
	
    render() {
      const { variables } = this.state;
	   var footerText  = 'العودة إلى الأعلى';
      if (typeof variables !== "undefined" && variables !== '') {
	  	   footerText  = variables.footer_text
	   }
			return (
				<footer className="footer full-width">
					<div className="container">
						<div className="row">
							<div className="col-5 col-print-6 aligner">

								<span className="static-text-eng" dangerouslySetInnerHTML={{ __html: variables.footer_copyright }}></span>

							</div>
							<div className="col-7 col-print-6 align-left go-on-top pr-0">

                                <a href="javascript:void(0)" className="onTop">
									<span className="static-text" dangerouslySetInnerHTML={{ __html: footerText }}></span>
									<span className="goto-top"></span>
                                </a>

							</div>

						</div>
					</div>
				</footer>
			);
		
    }
}

export default Footer;
