import React, {Component} from 'react';

import Header from './Header';
import Footer from './Footer';
import HelperClass from "./HelperClass";

class Error extends Component {

  constructor(props) {
    super(props);
    this.helper = new HelperClass();
    this.state = {
      variables: [],
      base_path: this.helper.basepath(),
    };
  }
  componentDidMount() {
    const { base_path } = this.state;
    var VariablesAPI = '';
    if (this.helper.getOnlineStatus()) {
      VariablesAPI = base_path + '/drupal/initvariables';
    } else {
      VariablesAPI = base_path + '/json/initvariables.json';
    }
    fetch(VariablesAPI)
        .then(response => response.json())
        .then(data => this.setState({ variables: data }));
  }

  componentDidUpdate(prevProps, prevState) {
    window.addEventListener('load', this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.pageHeight.bind(this));
    window.setTimeout(this.helper.pageHeight, 1000);
  }

    render() {
      const { variables } = this.state;
        return (
            <div>
              <Header />
              <div className="content-block verticle-center full-width">
                <div className="container">
                  <div className="home-page-content error-page clearfix">
                    <h1>404</h1>
                    <p dangerouslySetInnerHTML={{ __html: variables.page_not_found_text}}></p>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
        );
    }
}

export default Error;