import React, { Component } from 'react';
import $ from "jquery";

import Header from './Header';
import Footer from './Footer';
import HelperClass from './HelperClass.jsx'

// Static images import
import booksvg from '../assets/images/icons/book.svg';
import placholdericon from '../assets/images/icons/placeholderIcon.svg';
import servicelady from '../assets/images/illustration/assistance_mascottes.jpg';

class Jobsingle extends Component {
    constructor(props) {
        super(props);
        this.helper = new HelperClass();
        this.state = {
            Singles: [],
            CountNum: 0,
            variables: [],
            RelatedJobs: [],
            base_path: this.helper.basepath(),
        };
    }
    componentDidMount() {
        const { base_path } = this.state;
        var LISTAPI, VariablesAPI = '';
        if (this.helper.getOnlineStatus()) {
            LISTAPI = base_path + '/drupal/job-detail';
            VariablesAPI = base_path + '/drupal/initvariables';
        } else {
            LISTAPI = base_path + '/json/job-detail.json';
            VariablesAPI = base_path + '/json/initvariables.json';
        }
        fetch(LISTAPI)
            .then(response => response.json())
            .then(data => this.setState({ Singles: data.filter(c => c.nid === this.props.match.params.id), RelatedJobs: data.filter(c => c.nid !== this.props.match.params.id) }));
        fetch(VariablesAPI)
            .then(response => response.json())
            .then(data => this.setState({ variables: data }));
    }

    componentDidUpdate(prevProps, prevState) {
        // Adjusting functions
        this.helper.playAudio();
        this.helper.ApendContent();
        window.addEventListener("resize", this.helper.ApendContent.bind(this));
        window.addEventListener('load', this.helper.pageHeight.bind(this));
        window.addEventListener("resize", this.helper.pageHeight.bind(this));
        window.addEventListener("resize", this.helper.gotoTop.bind(this));
        this.helper.goOnTop();
        this.helper.shareIcons();
        window.setTimeout(this.helper.gotoTop, 1000);
        window.setTimeout(this.helper.pageHeight, 1000);

    }

    renderVideo(Single) {
        if (Single.field_video !== '') {
            return <div className="row">
        <div className="col-12 res-frame">
          <article className="img-holder pl-15 pr-15 pt-15">
            { Single.field_video_preview_image !== '' &&
              <video poster={this.state.base_path + Single.field_video_preview_image} controls>
                <source src={ this.state.base_path + Single.field_video }
                        type="video/mp4"/>
                Your browser does not support HTML5 video.
              </video>
            }
            { Single.field_video_preview_image === '' &&
            <video controls>
              <source src={ this.state.base_path + Single.field_video }
                      type="video/mp4"/>
              Your browser does not support HTML5 video.
            </video>
            }
            <div className="full-width">
              <h2>{ Single.field_video_title }
              </h2>
              <a href={ this.state.base_path + Single.field_video } className="btn btn-start-quiz mb-20">
                <strong> تحميل الفيديو
                </strong>
                {Single.field_video_file_size !== '' &&
               ' ' + Single.field_video_file_size + ' ' + 'الميغابايت '
                }
                <span
                    className="download">
                    </span>
              </a>
            </div>
          </article>
        </div>
      </div>;
        }
    }

    renderRelatedJobs(output, taxo, isQuizSingleView, base_path) {
      var shuffle = [];
      if(output !== undefined && output.length !== 0) {
        shuffle = this.helper.shuffle(output);
      }
      return  <div className="row">
          <div className="col-12">
            {taxo && isQuizSingleView && output.length !== 0 &&
            <section className="listed-jobs listed-jobs-tab full-width mt-15">
              <div className="row">
                <div className="col-12 pd-tab-00">
                  <h3>وظائف مماثلة</h3>
                </div>
              </div>

              <div className="row all-jobs single-page-jobs mt-15">
                {shuffle.slice(0, 6).map(item =>
                    <div key={item.nid} className="col-12 col-sm-6 col-lg-4 col-print-4">
                      <a href={'#/jobsingle/' + item.nid} onClick={ () => window.location.reload()}>
                        <article className="job-items">
                          <figure>
                            <img src={ base_path + item.field_job_image } alt="Job tittle" className="img-horzontal"/>
                          </figure>
                          <div className="full-width ar-title pl-15 pr-15">
                            <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                          </div>
                          <div className="full-width fr-title pl-15 pr-15">
                            <h2 dangerouslySetInnerHTML={{ __html: item.field_french_title }}></h2>
                          </div>
                        </article>
                      </a>
                    </div>
                )}
              </div>
            </section>
            }
          </div>
        </div>;
    }


    render() {
        const width = $(document).width();
        // Change the base path according to the server.
        const { base_path } = this.state;
        const { Singles } = this.state;
        const { variables } = this.state;
        const { RelatedJobs } = this.state;

        var PreviousPage = localStorage.getItem('previouPage');
      var taxo = localStorage.getItem('taxoQuiz');
		if(PreviousPage != null && taxo != null){
			var PreviousPageArray = [];
			PreviousPageArray = PreviousPage.split('/');
			var isQuizSingleView = false;
			if(PreviousPageArray.indexOf('listquiz') > -1) {
        isQuizSingleView = true;
      }
			taxo = taxo.split(',');

			if (taxo && isQuizSingleView) {
				var compet = [];
				var arrayLength = RelatedJobs.length;
				var taxoLength = taxo.length;
				var newList1 = [];
        var flag = [];
        var flag1 = true;
        var trueC = 0;
        for (var i = 0; i < arrayLength; i++) {
          trueC = 0;
          flag = [];
          flag1 = true;
          compet = RelatedJobs[i].field_competences;
          compet = compet.split(',');
          for (var j = 0; j < taxoLength; j++) {

            if(compet.indexOf(taxo[j]) > -1){
              flag[j] = true;
            }else{
              flag[j] = false;
            }

          }
          for (var h = 0; h < flag.length; h++) {
            if(flag[h] === true) {
              trueC++;
            }
          }
          if(compet.length === trueC) {
            newList1.push(RelatedJobs[i]);
          }
        }


				// Removing the empty index from array.
				var output = [],
					uniqueoutput = [];
				for (i = 0; i < newList1.length; i++) {
					uniqueoutput[newList1[i].nid] = newList1[i];
				}
				// Removing the empty index from array.
				output = uniqueoutput.filter(function(el) {
					return el != null;
				});
			}
        }

        return (
            <div className="Home">

              {Singles.map(Single => <Header key={Single.nid} audioFile={Single.field_job_audio_file} basepath={base_path} />) }

              {Singles.map(Single =>
              <div key={ Single.nid } className="content-block full-width pt-0 list-page-single">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <section className="single-page-img full-width mb-40">
                        <div className="row">
                          <div className="col-12 res-frame">
                            <article className="img-holder">
                              <figure>
                                <img src={ base_path + Single.field_job_image } alt="Job tittle" className="img-horzontal"/>
                              </figure>
                                  <div className="full-width pl-15 pr-15">
                                    <h1 dangerouslySetInnerHTML={{ __html: Single.title }}></h1>
                                  </div>
                            </article>
                              <div className="full-width light-gray-bg">
                                  <p className="video-desc-eng" dangerouslySetInnerHTML={{ __html: Single.field_french_title }}>
                                  </p>
                              </div>
                          </div>
                        </div>
                      </section>
                      <section className="single-page-img full-width mb-40">
                        { this.renderVideo(Single) }
                      </section>
                      <section className="articles-mob full-width pb-10">
                        <div className="row posted-articles">
                          <div className="col-12 pd-tab-00">
						  {Single.field_job_definition !== '' &&
								<article className="articles">
								  <div dangerouslySetInnerHTML={{ __html: Single.field_job_definition }}></div>
								</article>
							}
                          </div>
                          <div className="col-12 pd-tab-00">
							   {Single.field_job_access_condition !== '' &&
									<article className="articles">
									  <h3>
                                          <span className="heading-icons">
											<img
                                                src={placholdericon}
                                                alt="Heading"/>
										  </span>
										  <span className="heading-text">{(typeof variables !== "undefined" && variables.job_access_conditions_label !== '') ? variables.job_access_conditions_label : 'أنشطة'}</span>

									  </h3>
									  <div dangerouslySetInnerHTML={{ __html: Single.field_job_access_condition }} ></div>
									</article>
								}
                          </div>
                          <div className="col-12 pd-tab-00">
							  {Single.field_job_suitable !== '' &&
									<article className="articles">
									  <h3>
                                          <span
                                              className="heading-icons">
											<img src={booksvg}
                                                 alt="Heading"/>
										  </span>
										  <span
											  className="heading-text">{(typeof variables !== "undefined" && variables.job_suitable_for_you_label !== '') ? variables.job_suitable_for_you_label : 'معلومات عامة'}
										  </span>

									  </h3>
									  <div dangerouslySetInnerHTML={{ __html: Single.field_job_suitable }} ></div>
									</article>
								}
                          </div>
                          <div className="col-12 pd-tab-00">
						   {Single.field_additional_job_paragraphs !== '' &&
								<article className="articles">
								  <h3>
                                      <span className="heading-icons">
										<img
                                            src={placholdericon}
                                            alt="Heading"/>
									  </span>
									  <span className="heading-text">{(typeof variables !== "undefined" && variables.additional_job_paragraph_label !== '') ? variables.additional_job_paragraph_label : 'أنشطة'}
									  </span>

								  </h3>
								  <div dangerouslySetInnerHTML={{ __html: Single.field_additional_job_paragraphs }}></div>
								</article>
							}
                          </div>
                        </div>
                      </section>
					   {Single.field_external_links !== '' &&
						  <section className="external-links ext-link-tab full-width mb-40">
							<div className="row">
							  <div className="col-12 pad-tab-00 pd-00">
								<div className="gray-bg full-width">
								  <h3>{(typeof variables !== "undefined" && variables.external_links_label !== '') ? variables.external_links_label : 'روابط خارجية'}
								  </h3>
								  <ul dangerouslySetInnerHTML={{ __html: Single.field_external_links }}></ul>
								</div>
							  </div>
							</div>
						  </section>
						}
                      <section className="contact-details full-width mb-40">
                        <div className="row">
                          <div className="col-12 pd-tab-00 pd-00">
                            <div className="pink-bg full-width">
                              <div className="row">
                                <div className="col-7 pl-0">
                                  <h4>
                                   للمزيد من المعلومات، المرجو الإتصال بمستشار ANAPEC في اقرب وكالة لكم
                                  </h4>
                                </div>
                                <div className="col-5 verticle-center ">
                                  <figure>
                                    <img
                                        src={servicelady}
                                        alt="Contact"
                                        className="img-vertical"/>
                                  </figure>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      <section className="available-list full-width">
                        <div className="row">
                          <div className="col-12">
                            { width > 1024 &&
                            <div>
                              <p className="mb-25">{variables.download_description}</p>
                              {/*<a href="mailto:#" className="btn btn-start-quiz mb-20">إرسال إلى بريد إلكتروني   <span className="send-mail"></span></a>*/}
                              <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                            </div>
                            }
                            { width < 1024 &&
                            <div>
                              <p className="mb-25">{ variables.share_description }</p>
                              <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                              <span className="share-icons">
									   <button href="#" className="btn btn-start-quiz mb-20 share-btn">إرسال
										   <span className="share"></span>
										</button>
										<span className="social-icons clearfix">
											<ul>
												<li><a rel="noopener noreferrer" target="_blank" href={ "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash)  }
                               className="fb"></a></li>
											  <li><a rel="noopener noreferrer" target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }
                               className="wt"></a></li>
                        {/*<li><a target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }*/}
                        {/*className="em"></a></li>*/}
											</ul>
										</span>
									</span>
                            </div>
                            }
                          </div>
                        </div>
                      </section>
                    </div>
                    <div className="col-12 col-md-6 tablet-articles">
                    </div>

                  </div>



                  { this.renderRelatedJobs(output,taxo, isQuizSingleView, base_path) }
                </div>
              </div>
              )}
                <Footer />
            </div>
        );
    }
}

export default Jobsingle;