/*
 * This class clubbing all function which is supporting ReactJS.
 */
import $ from "jquery";

class  HelperClass {

  constructor() {
    this.LiveURL = "http://orientation-prof.anajit.org";
    this.Livehostname = 'orientation-prof.anajit.org';
  }

  // for social media share.
  getLiveURL() {
    return this.LiveURL;
  }


  basepath() {
    return window.location.protocol + "//" + window.location.host;
  }

  getOnlineStatus() {
    if (window.location.hostname === this.Livehostname) {
      return true;
    }else{
      return false;
    }
  }

  /* jQuery Related function */

  /*Append content on different container in job-glossary-single page start */
  ApendContent() {
    var windowWidth = $(window).innerWidth();
    if(windowWidth > 767) {
      $('.posted-articles').appendTo($('.tablet-articles'));
      $('.articles-mob').hide();
    }
    else {
      $('.posted-articles').appendTo($('.articles-mob'));
      $('.articles-mob').show();
    }
  }

  /* Audio play and pause start */
  playAudio() {
      $('.audio-state').on('click', function (e, param) {
        if(e.originalEvent !== undefined || param === 'autoplay') {
          e.stopImmediatePropagation();
          var el = $(this).hasClass('audio-state-pause');
          var audio = $('#myAudio')[0];
          if (el) {
            $(this).removeClass('audio-state-pause').addClass('audio-state-play');
            audio.play();
          } else {
              $(this).removeClass('audio-state-play').addClass('audio-state-pause');
              audio.pause();
          }
        }
      });
  }

  autoPlay() {
	$('.audio-state').trigger('click', ["autoplay"]);
  }
  autoPause() {
    var audio = $('#myAudio')[0];
    $('.audio-state').removeClass('audio-state-play').addClass('audio-state-pause');
    //audio.pause();
  }

  audioEnded() {
      $('#myAudio').on('ended', function (e) {
          e.stopImmediatePropagation();
          $('.audio-state').addClass('audio-state-pause').removeClass('audio-state-play');
      })
  }

  /* Audio play and pause end */


  /* show or hide social-icons on share click start */
  shareIcons() {
      $(document).on('click', '.share-btn', function (e) {
          e.stopImmediatePropagation();
          $(this).toggleClass('active');
      });
  }
    /* show or hide social-icons on share click end */

    /* Set height of content section start */
    pageHeight() {
        var flb = $('.footer-link-block');
        var flb_val = flb.outerHeight();
        if(flb.length > 0) {
            flb_val = flb.innerHeight();
        }
        else {
            flb_val = 0;
        }
        var contentHeight = $(window).innerHeight() - (
            $('.header').innerHeight() +
            flb_val +
            $('.footer').innerHeight()
        );
        $('.content-block').css('min-height', contentHeight);

    }
    /* Set height of content section end */

    /* Show or hide the Go to top button on scroll and not scroll start */
    gotoTop() {
        var bodyHt = $(window).height() + 1;
        var pageHt = $(document).innerHeight();
        if (bodyHt >= pageHt) {
            $('.go-on-top').css('display', 'none');
        } else {
            $('.go-on-top').css('display', 'block');
        }
        return false;

        /* Show or hide the Go to top button on scroll and not scroll end */
    }

    /* Go to top animated start */
    goOnTop() {
        $(document).on('click', '.onTop', function () {
             //event.preventDefault();
             $('html, body').animate({
                 scrollTop: 0
             }, 500);

       });
        /* Go to top animated end */
    }

    /* Job list page show or hide navigation start */
    jobFilter() {
        $('.job-filter-btn').on('click', function (e) {
            e.stopImmediatePropagation();
            $(this).children().toggleClass('close');
            var el = $(this).parent().next();
            el.slideToggle();
            return false;
        });
    }
    /* Job list page show or hide navigation end */

    /*Blink Audio start*/

    blink() {
        $('.audio-header').addClass('audio-blink');
    }
    /*Blink Audio end*/

    /* Sticky strip on home page start */
    stickyStrip() {
        var footerlinkHt = $('.footer-link-block').innerHeight();
        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(".footer").offset().top;
            if ((elemTop <= docViewBottom) && (elemTop >= docViewTop)) {
                $(".footer-link-block").removeClass("fixed-bottom");
                $(".footer").removeClass("margintop");
                $('.footer').removeAttr('style');
            }
            else {
                $(".footer-link-block").addClass("fixed-bottom");
                $(".footer").css('margin-top', footerlinkHt);
            }
        }
        isScrolledIntoView();
        $(window).scroll(function () {
            isScrolledIntoView();
            return false;
        });
    }

    /* Sticky strip on home page end */



  /**
   * Shuffles array in place. ES6 version
   * @param {Array} a items An array containing the items.
   */
  shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  }
}

export default HelperClass;