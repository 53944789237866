import React, { Component } from 'react';



import Header from './Header';
import Footer from './Footer';
import {Redirect} from "react-router";
import HelperClass from './HelperClass.jsx'
import $ from "jquery";


class Listquiz extends Component {

    constructor(props) {
        super(props);
        this.helper = new HelperClass();
        this.state = {
            lists: [],
            newList: [],
            base_path: this.helper.basepath(),
            variables: [],
            sectors: [],
			quizlist: [],
            filterq: localStorage.getItem("filterq") === null ? [] : JSON.parse(localStorage.getItem('filterq')),
        };
    }

    componentDidMount() {
      const { base_path } = this.state;
      var LISTAPI, VariablesAPI, SECTORS, DOORPAGE = '';
      if(this.helper.getOnlineStatus()) {
        LISTAPI = base_path + '/drupal/job-detail';
        VariablesAPI = base_path + '/drupal/initvariables';
        SECTORS = base_path + '/drupal/sectors';
		    DOORPAGE = base_path + '/drupal/doorpage';
      } else {
        LISTAPI = base_path + '/json/job-detail.json';
        VariablesAPI = base_path + '/json/initvariables.json';
        SECTORS = base_path + '/json/sectors.json';
		    DOORPAGE = base_path + '/json/doorpage.json';
      }
        fetch(LISTAPI)
            .then(response => response.json())
            .then(data => this.setState({ lists: data }));
        fetch(VariablesAPI)
          .then(response => response.json())
          .then(data => this.setState({ variables: data }));
        fetch(SECTORS)
          .then(response => response.json())
          .then(data => this.setState({sectors: data}));
		fetch(DOORPAGE)
			.then(response => response.json())
			.then(data => this.setState({quizlist: data.filter(p => p.field_machine_name === 'quizlist')}));
    }

  componentDidUpdate(prevProps, prevState) {
    // Adjusting functions
    this.helper.playAudio();
    window.addEventListener('load', this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.pageHeight.bind(this));
    window.addEventListener("resize", this.helper.gotoTop.bind(this));
    this.helper.goOnTop();
    this.helper.shareIcons();
    this.helper.jobFilter();
    window.setTimeout(this.helper.pageHeight, 1000);
    window.setTimeout(this.helper.gotoTop, 1000);

  }

  getSectors(output) {
    const { sectors } = this.state;
    // get tid
    var sec = [];
    for (var i = 0 ; i < output.length ; i++) {
      sec[output[i].field_sector] = output[i].field_sector;
    }
    sec = sec.filter(function (el) {
      return el != null;
    });
    var filsec = [];
    for (i = 0 ; i < sectors.length; i++) {
      for (var j = 0; j < sec.length; j++) {
        if(sectors[i].tid === sec[j]) {
          filsec.push(sectors[i]);
        }
      }
    }
    return filsec;
  }

  handleChecked (event) {
    var temp = this.state.filterq;
    if(event.target.checked) {
      temp.push(event.target.value);
      this.setState({filterq: temp});
      localStorage.setItem('filterq', JSON.stringify(temp));
    }else{
      var temp1 = this.removeFilterItem(temp, event.target.value);
      this.setState({filterq: temp1});
      localStorage.setItem('filterq', JSON.stringify(temp1));
    }
  }
  removeFilterItem (filter ,removeItem) {
    filter = filter.filter(function(item) {
      return item !== removeItem
    });
    return filter;
  }

    render() {
      const width = $(document).width();
      const { variables } = this.state;
      const { base_path } = this.state;
      const { lists } = this.state;
	  const { quizlist } = this.state;
      var param = this.props.match.params.u;
	  localStorage.setItem('previouPage', window.location.hash);
      var taxo = '';
      // check the param is only contain the numbers and comma
      if(/^[0-9,.]*$/.test(param)) {
        taxo = param;
      }else{
        taxo = localStorage.getItem('taxoQuiz');
      }
      taxo = taxo.split(',');
      if (taxo) {
        var compet = [];
        var arrayLength = lists.length;
        var taxoLength = taxo.length;
        var newList1 = [];
        var flag = [];
        var flag1 = true;
        var trueC = 0;
        for (var i = 0; i < arrayLength; i++) {
          trueC = 0;
          flag = [];
          flag1 = true;
          compet = lists[i].field_competences;
          compet = compet.split(',');
          for (var j = 0; j < taxoLength; j++) {

            if(compet.indexOf(taxo[j]) > -1){
              flag[j] = true;
            }else{
              flag[j] = false;
            }

          }
          for (var h = 0; h < flag.length; h++) {
            if(flag[h] === true) {
              trueC++;
            }
          }
          if(compet.length === trueC) {
            newList1.push(lists[i]);
          }
        }
		
        // Removing the empty index from array.
        var output = [], uniqueoutput = [];
        for(i = 0; i < newList1.length; i++) {
          uniqueoutput[newList1[i].nid] = newList1[i];
        }
        // Removing the empty index from array.
        output = uniqueoutput.filter(function (el) {
          return el != null;
        });
		
		// Redirect to no profile page when no results are there
		if(lists.length !== 0 && output.length === 0){
			return <Redirect to={{
				pathname: '/Noprofile'
				}} />;
		}
		
        // Get Sectors
        var sectors = this.getSectors(output);
        // filter according to the sectors
        if(this.state.filterq.length !== 0) {
           var tempoutput = output;
           output = [];
           for (i = 0; i < tempoutput.length; i++) {
             if(this.state.filterq.indexOf(tempoutput[i].field_sector) > -1) {
               output.push(tempoutput[i]);
             }
           }
        }
        const count = output.length;
		
        //sorting
        var varsort = [];
        if(output !== undefined && output.length !== 0) {
          for (i = 0; i < output.length; i++) {
            varsort[output[i].field_weight] = output[i];
          }
        }
        return (
            <div className="Home">
              {quizlist.map(page => <Header key={page.field_machine_name} audioFile={page.field_job_audio_file} basepath={base_path} />) }
              <div className="content-block full-width">
                <div className="container">
                  <section className="job-list full-width">
                    <div className="row">
                       {quizlist.map(page => 
					   <div key={page.field_machine_name}  className="col-12 page-descriptions mb-30"><h1>{page.title}</h1>
					   <div dangerouslySetInnerHTML={{ __html: page.body }}></div>
						</div>
						)}
                    </div>

                    <div className="row">
                      <div className="col-12 padding-gutters">
                        <div className="full-width job-filter">
                          <button type="button" className="btn job-filter-btn">
                            <span className="job-filter-icon"></span>
                            إختيار القطاعات
                          </button>
                        </div>
                        <div className="full-width job-filter-items">
                          <ul>
                            {
                              sectors.map(sector =>
                                  <li key={sector.tid}><input value={sector.tid} onChange={ (event) => this.handleChecked(event) } type="checkbox" className="checkbox-class" id={sector.tid} checked={this.state.filterq.indexOf(sector.tid) > -1 ? 'checked' : ''} /><label htmlFor={sector.tid}><span className="label-tag"><span className="ar" ><span dangerouslySetInnerHTML={{ __html:sector.name }}></span><span className="filter-icons"><img
                                      src={base_path + sector.field_sector}
                                      alt="icons"/></span></span><span className="en" dangerouslySetInnerHTML={{ __html: sector.field_french_name }}></span></span>
                                  </label>
                                  </li>
                              )
                            }
                          </ul>
                        </div>
                      </div>
                    </div>

                  </section>

                  <section className="listed-jobs full-width">
                    { count !== 0 &&
                    <div className="row no-gutters">
                      <div className="col-12">
                        <div className="list-counts mt-35 mb-10">{count} مهنة</div>
                      </div>
                    </div>
                    }
                    <div className="row all-jobs job-glossary-items">
                      {varsort.map(item =>
                          <div  key={item.nid} className="col-12 col-sm-6 col-lg-4 col-print-4 job-item-blocks">
                            <a href={'#/jobsingle/' + item.nid}>
                              <article className="job-items">
                                <figure>
                                  <img src={ base_path + item.field_job_image } alt="Job tittle" className="img-horzontal"/>
                                </figure>
                                <div className="full-width ar-title pl-15 pr-15">
                                  <h2 dangerouslySetInnerHTML={{ __html: item.title }}></h2>
                                </div>
								
								 <div className="full-width fr-title pl-15 pr-15">
									   <h2 dangerouslySetInnerHTML={{ __html: item.field_french_title }}></h2>
								  </div>
                              </article>
                            </a>
                          </div>
                      )}
                    </div>

                  </section>
                  <section className="available-list full-width mt-20">
                    <div className="row">
                      <div className="col-12">
                        { width > 1024 &&
                        <div>
                          <p className="mb-25" dangerouslySetInnerHTML={{ __html: variables.download_description }}></p>
                          {/*<a href="mailto:#" className="btn btn-start-quiz mb-20">إرسال إلى بريد إلكتروني   <span className="send-mail"></span></a>*/}
                          <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                        </div>
                        }
                        { width < 1024 &&
                        <div>
                          <p className="mb-25" dangerouslySetInnerHTML={{ __html: variables.share_description }}></p>
                          <a href="javascript:void(0)" onClick={() => window.print()} className="btn btn-start-quiz">تحميل<span className="download"></span></a>
                          <span className="share-icons">
									   <button href="#" className="btn btn-start-quiz mb-20 share-btn">إرسال
										   <span className="share"></span>
										</button>
										<span className="social-icons clearfix">
											<ul>
												<li><a rel="noopener noreferrer" target="_blank" href={ "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash)  }
                               className="fb"></a></li>
											  <li><a rel="noopener noreferrer" target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }
                               className="wt"></a></li>
                        {/*<li><a target="_blank" href={"whatsapp://send?text=" + encodeURIComponent(this.helper.getLiveURL() + '/' + window.location.hash) }*/}
                        {/*className="em"></a></li>*/}
											</ul>
										</span>
									</span>
                        </div>
                        }
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <div className="goto-dictionary full-width">
                <a href="#/list" className="btn btn-job-glossary">العودة إلى لائحة القطاعات و المهن</a>
              </div>
              <Footer />
            </div>
        );
      }else{
        return <Redirect to={{
          pathname: '/quiz'
        }} />;
      }
    }
}

export default Listquiz;
