import React, { Component } from 'react';
import BrowserHistory from 'browser-history';
import ReactGA from 'react-ga';

import weblogo from '../assets/images/web-logo.png';
import HelperClass from "./HelperClass";

class Header extends Component {

  constructor(props){
    super(props);
    this.helper = new HelperClass();
    this.state = {
      variables: [],
      base_path: this.helper.basepath(),
    };
  }

  componentDidMount() {
    const { base_path } = this.state;
    var VariablesAPI = '';
    if(this.helper.getOnlineStatus()) {
      VariablesAPI = base_path + '/drupal/initvariables';
    }else{
      VariablesAPI = base_path + '/json/initvariables.json';
    }
    fetch(VariablesAPI)
        .then(response => response.json())
        .then(data => this.setState({ variables: data }));
  }

  googleTracking() {
    const { variables } = this.state;
    ReactGA.initialize(variables.google_gid);
    ReactGA.pageview(window.location.hash);
  }
   componentDidUpdate() {
      this.helper.audioEnded();
   }
  render() {
      // Invoke the googleTracking
      this.googleTracking();

      const audio = this.props.audioFile;
      const basepath = this.props.basepath;
	  
      if(typeof audio != 'undefined' && audio !== '') {
        return (
            <header className="header">
              <div className="header-component verticle-center clearfix">
            <span className="audio-header">
                <a href="javascript:void(0)"
                   className="audio-state audio-state-pause">

                </a>
            </span>
                <span className="hidden-audio">
                    <audio key={basepath + audio} id="myAudio" controls ref="audio">
                        <source src={ basepath + audio } type="audio/ogg"/>
                        <source src={ basepath + audio } type="audio/mpeg"/>
                    </audio>
                </span>
                <div className="container">
                  <div className="home-logo clearfix">
                    <a href="/" ><img src={weblogo} alt="Logo"/></a>
                  </div>
                </div>
                {window.location.hash !== '#/' &&
                <a onClick={() => BrowserHistory(-1)} href="javascript:void(0)"
                   className="header-next"></a>
                }
              </div>
            </header>
        );
      }
      else {
        return (
            <header className="header">
				<div className="header-component verticle-center clearfix">
					<div className="container">
                  <div className="home-logo clearfix">
                    <a href="/" ><img src={weblogo} alt="Logo"/></a>
                  </div>
                </div>
                {window.location.hash !== '#/' &&
                <a onClick={() => BrowserHistory(-1)} href="javascript:void(0)"
                   className="header-next"></a>
                }
				</div>
            </header>
        );
      }
  }
}

export default Header;
